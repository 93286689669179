@import '../../../shared/styles/typography';
@import '../../../shared/styles/breakpoints';

.content {
    h2 {
        margin-bottom: 4rem;
        @include heading-h2();
        @include media-breakpoint-only(xs) {
            margin-bottom: 3rem;
        }
    }

    h3 {
        margin-bottom: 4rem;
        @include heading-h3();
        @include media-breakpoint-only(xs) {
            margin-bottom: 3rem;
        }
    }

    p {
        margin-bottom: 4rem;
        text-align: left;
        @include text-size-regular();
        @include text-color-text();
        @include media-breakpoint-down(xs) {
            margin-bottom: 3rem;
        }
    }
}
